<template>
  <b-card>
    <header-table title="المرافق السياحية" @create="onAdd" />

    <data-table ref="estatesTableee" :fields="fields" ep="/tourist-facilities">
      <template #cell(actions)="row">
        <b-button variant="flat-info" title="عرض المزيد من المعلومات" size="sm" class="" @click="row.toggleDetails"
          v-b-toggle.collapse-1>
          المزيد
          <feather-icon :icon="row.detailsShowing ? 'ChevronUpIcon' : 'ChevronDownIcon'" />
        </b-button>
        <actions-table @delete="onDelete(row.item)" @update="onUpdateClicked(row.item)" />
      </template>
      <template #cell(tourist_facility_type_id)="row">
        <div>
          {{ row.item.touristFacilityType.name }}
        </div>
      </template>
      <template #row-details="row">
        <b-collapse id="collapse-1" class="mt-2">
          <div>
            <b-row>
              <b-col cols="4">
                <b-card class="border">
                  <h5>الخريطة</h5>
                  <Map :lng="row.item.longitude" :lat="row.item.latitude"></Map>
                </b-card>
              </b-col>
              <b-col cols="4">
                <h4 class="text-primary">صورة المرفق</h4>
                <b-img :src="row.item.image" class="w-100 h-75 pt-2  " />
              </b-col>
              <b-col cols="4">
                <h4 class="text-primary">التفاصيل</h4>
                <p class="pt-2 ">
                  {{ row.item.description }}
                </p>

                <h4 class="text-primary">العنوان</h4>

                <p class="pt-2 ">
                  {{ row.item.address }}
                </p>
              </b-col>

            </b-row>
          </div>
        </b-collapse>
      </template>
    </data-table>
    <form-modal ref="estateModalAboutContent" :formSchema="estatesFormSchema" title="إضافة" v-model="activModel"
      :loading="loading" @confirm="onModalConfirmed">
      <template #long>
        <div>
          <b-row>
            <b-col cols="6">
              <form-input label="خط العرض" v-model="markerPos.lat" rules="required" />
            </b-col>
            <b-col cols="6">
              <form-input label="خط الطول" v-model="markerPos.lng" rules="required" />
            </b-col>

            <b-col cols="12"><b-button class="w-100 outline" variant="outline-primary" @click="mapModal = true">
                <feather-icon icon="MapIcon" /> </b-button></b-col>
          </b-row>

          <b-modal v-model="mapModal" centered size="lg" title="" footer-class="removed" class="edit-modal">
            <select-map @markerUpdated="onLocationSelect" :lat="markerPos.lat" :lng="markerPos.lng"> </select-map>
            <LoaderBtn variant="primary" @click="mapModal = false"></LoaderBtn>
          </b-modal>
        </div>
      </template>


      <template #image="{ form }">
        <form-file label="صورة" accept="image/*" v-model="image" id="form-file" name="image"
          placeholder="لم تقم باختيار صور" @change="previewImage" />
        <div class="text-center mb-1">
          <layout-input-image v-if="staticimage.url" :url="staticimage.url" :name="''" :is_last_imge="false"
            @deleteItem="deleteImage()">
          </layout-input-image>
          <layout-input-image v-if="!staticimage.url && form.image" :url="form.image" :name="''" :is_last_imge="false"
            :is_delete="false" />
        </div>
      </template>

    </form-modal>
  </b-card>
</template>
<script>

import Map from "@/components/map.vue";
import { BCard, BImg, BRow, BCol, BModal, BButton, BCollapse, VBToggle } from "bootstrap-vue";
import { mapActions, mapGetters } from "vuex";
import DataTable from "@/components/data-table/index.vue"
import FormModal from "@/components/FormModal.vue"
import HeaderTable from "@/components/data-table/components/header-table.vue"
import ActionsTable from "@/components/data-table/components/actions-table.vue"
import FormFile from "@/components/form-file/index.vue"
import dataSchema from "./mixin"
import LayoutInputImage from "@/components/LayoutInputImage.vue";
import FormInput from "@/components/form-input/index.vue";
import SelectMap from "@/components/select-map/index.vue";
import LoaderBtn from "@/components/loader-btn.vue";



export default {
  components: {Map, VBToggle, BCollapse, LoaderBtn, SelectMap, FormInput, BImg, BRow, BCol, BButton, BModal, BCard, DataTable, FormModal, HeaderTable, ActionsTable, FormFile, LayoutInputImage },
  directives: {
    "b-toggle": VBToggle,
  },
  data() {
    return {
      mapModal: false,
      activModel: false,
      image: [],
      staticimage: { url: '' }
    };
  },
  mixins: [dataSchema],
  methods: {
    ...mapActions("manageCenter/discoverAncientCity", ["update", "delete", "create"]),

    onLocationSelect(data) {
      this.markerPos.lng = data.lng;
      this.markerPos.lat = data.lat;
    },
    updateMarker(x) {
      this.markerPos = x;
    },

    previewImage(e) {
      this.staticimage = {
        url: URL.createObjectURL(e.target.files[0]),
      }
    },
    deleteImage() {
      this.staticimage.url = ''
      this.image = null
    },
    onAdd() {
      this.$refs.estateModalAboutContent.init({});
      this.markerPos.lat = "";
      this.markerPos.lng = "";
      this.deleteImage()
      this.activModel = true;
    },
    onDelete(item) {
      this.$bvModal
        .msgBoxConfirm("هل أنت متأكد من حذف", {
          title: "تأكيد حذف",
          size: "sm",
          okVariant: "danger",
          okTitle: "تأكيد",
          cancelTitle: "إلغاء",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value)
            this.delete({ ep: `/tourist-facilities/${item.id}` }).then(() => {
              this.$refs.estatesTableee.refreshTable();
            });
        });
    },
    onUpdateClicked(form) {
      this.deleteImage()
      this.markerPos.lng = form.longitude;
      this.markerPos.lat = form.latitude;
      this.$refs.estateModalAboutContent.init({ ...form, tourist_facility_type_id: form.touristFacilityType.id });
      this.activModel = true;
    },
    onModalConfirmed(form) {
      var fd = new FormData();
      for (const key in form) {
        if (Object.hasOwnProperty.call(form, key)) {
          const element = form[key];
          if (form[key] != null) {
            if (key == "cost" || key == "max_member")
              fd.append(key, element.replace(/\D/g, ""));
            else fd.append(key, element);
          }
        }
      }
      if (this.image) {
        fd.append('image', this.image)
      }
      fd.append("longitude", this.markerPos.lng);
      fd.append("latitude", this.markerPos.lat);

      if (form.id) {
        fd.append('_method', 'PUT')
        this.update({ data: fd, ep: `/tourist-facilities/${form.id}` }).then(() => {
          this.$refs.estatesTableee.refreshTable();
          this.activModel = false;
        });
      } else
        this.create({ data: fd, ep: `/tourist-facilities` }).then(() => {
          this.$refs.estatesTableee.refreshTable();
          this.activModel = false;
        });
    },
  },
  computed: {
    ...mapGetters("manageCenter/discoverAncientCity", ["loading"]),
  },
};
</script>